<template>
  <div>
    <div class="d-flex justify-content-between">
      <h5 class="d-flex align-items-end font-weight-bolder">
        {{ label }}
      </h5>
      <button
        type="button"
        class="btn btn-primary"
        @click="onAddTenant"
      >
        Thêm người
      </button>
    </div>
    <vue-good-table
      class="pt-1"
      :columns="columns"
      :rows="tenants"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'id'">
          {{ props.formattedRow[props.column.field ] }}
        </span>
        <span v-else-if="props.column.field === 'gender'">
          <validation-provider
            #default="validationContext"
            name="gender"
            rules="required"
          >
            <v-select
              id="gender"
              v-model="props.row.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['Nam', 'Nữ']"
              placeholder="Giới tính"
              :append-to-body="true"
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            />
            <small class="text-danger">{{
              validationContext.errors[0]
            }}</small>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field === 'birthday'">
          <validation-provider
            #default="validationContext"
            name="birthday"
            rules="required"
          >
            <select-date
              v-model="props.row.birthday"
              required
              :default-value="props.row.birthday"
              :error="validationContext.errors[0]"
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            />
          </validation-provider>
        </span>

        <span v-else-if="props.column.field === 'action'">
          <table-actions
            name="Thông tin cư dân"
            :disable-edit="true"
            @on-delete="onDelete(props.row)"
          />
        </span>
        <span v-else>
          <validation-provider
            #default="validationContext"
            :name="props.column.field"
            rules="required"
          >
            <b-form-input
              v-model="props.row[props.column.field]"
              trim
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            />
            <small class="text-danger">{{
              validationContext.errors[0]
            }}</small>
          </validation-provider>
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import vSelect from 'vue-select';
import SelectDate from "@/views/components/SelectDate.vue";
import { VueGoodTable } from 'vue-good-table';
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    VueGoodTable,
    TableActions,
    vSelect,
    SelectDate,
    ValidationProvider,
    BFormInput,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tenants: this.rows.map((r, i) => ({ ...r, id: i + 1 })),
      required,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  methods: {
    changeCell(changedData, column, row) {
      this.tenants[row][column] = changedData;
      this.onChange();
    },
    onDelete(row) {
      const remainTenants = this.tenants.filter(tenant => tenant.id !== row.id);
      this.tenants = remainTenants;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.tenants);
    },
    onAddTenant() {
      const tempTenant = {
        id: this.tenants.length + 1,
        name: "",
        birthday: "",
        gender: "",
        idNumber: "",
        job: "",
        relation: "",
      };
      this.tenants.push(tempTenant);
      this.onChange();
    },
  },
  setup() {
    const columns = [
      {
        label: 'TT',
        field: 'id',
        tdClass: 'text-center',
        sortable: false,
      },
      {
        label: 'Họ, chữ đệm và tên',
        field: 'name',
        sortable: false,
        width: "200px",
      },
      {
        label: 'Ngày, tháng, năm sinh',
        field: 'birthday',
        sortable: false,
        width: "180px",
      },
      {
        label: 'Giới tính',
        field: 'gender',
        sortable: false,
        width: "150px",
      },
      {
        label: 'Số định danh cá nhân/CMND',
        field: 'idNumber',
        sortable: false,
        width: "220px",
      },
      {
        label: 'Nghề nghiệp, nơi làm việc',
        field: 'job',
        sortable: false,
        width: "250px",
      },
      {
        label: 'Quan hệ với chủ hộ',
        field: 'relation',
        sortable: false,
        width: "250px",
      },
      {
        label: 'Thao tác',
        field: 'action',
        tdClass: 'text-center',
        sortable: false,
        width: "90px",
      },
    ];

    return {
      columns,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style lang="scss" scope>
.vgt-table {
  font-size: 1rem !important;
}

.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>
